import React from "react"
import { RButton } from "../../studio/Views/Buttons"
import type { Button, ButtonProps } from "./Button"
import { usePerformWidgetAction } from "./WidgetContext"
import { RegisterWidget } from "./WidgetView"
import { ColorStyles } from "../ui"

RegisterWidget<Button>("Button", ({ value }) => <ButtonWidget {...value} />)

export function ButtonWidget({
    widgetKey,
    prompt,
    text,
    action,
    dropdown,
    disabled,
    icon,
    chevron,
    style,
    link,
    openLinkInNewTab,
}: ButtonProps) {
    const performAction = usePerformWidgetAction()

    const special = style === "download" || style === "print"

    return (
        <RButton
            key={widgetKey}
            variant={style === "primary" ? "primary" : special ? undefined : "secondary"}
            disabled={disabled}
            link={link}
            openLinkInNewTab={openLinkInNewTab}
            popup={
                dropdown
                    ? () => (
                          <div>
                              {dropdown.map((d) => (
                                  <ButtonWidget key={d.widgetKey} {...d} />
                              ))}
                          </div>
                      )
                    : undefined
            }
            style={
                special
                    ? {
                          color: ColorStyles.gray[700],
                      }
                    : undefined
            }
            icon={
                icon ??
                (style === "download"
                    ? "ui-download-cloud-02"
                    : style === "print"
                      ? "ui-printer"
                      : undefined)
            }
            chevron={chevron ?? (dropdown && !special ? "ellipsis-v" : undefined)}
            hideChevron={special}
            onClick={async () => {
                try {
                    if (link) return
                    if (dropdown) {
                        // This is a dropdown button, not an action button
                        return
                    }
                    if (prompt && !confirm(prompt)) {
                        return
                    }
                    if (action instanceof Function) {
                        void action()
                    } else if (widgetKey) {
                        await performAction(widgetKey)
                    } else {
                        console.log("No action specified")
                    }
                } catch (e: any) {
                    alert("detail" in e ? e.detail : "message" in e ? e.message : JSON.stringify(e))
                }
            }}>
            {special ? undefined : text}
        </RButton>
    )
}
