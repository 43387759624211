import React, { useContext, useState } from "react"
import { WidgetView } from "../../../../packages/widgets/WidgetView"
import { AccountContext } from "../AccountPage"
import { colors, Title } from "../styles"
import { Section } from "../../../../packages/editing/Section"

Section(EmissionsReportingSection)
function EmissionsReportingSection(section: {}) {
    const account = useContext(AccountContext)
    if (!account) return <></>

    const emissionReport = account?.emissionReport

    const Chart = (globalThis as any).Chart
    if (Chart) {
        Chart.defaults.font.family = "Jost"
    }
    const [year, setYear] = useState(
        emissionReport.length ? emissionReport[0].year : new Date().getFullYear()
    )

    return (
        <div className="container">
            <Title title="Emissions overview" />

            <div style={{ display: "flex", flexDirection: "row" }}>
                {emissionReport?.map((y) => (
                    <div
                        onClick={() => setYear(y.year)}
                        style={{
                            cursor: "pointer",
                            color: colors.darkGreen,
                            marginLeft: 32,
                            fontWeight: year === y.year ? "bold" : undefined,
                        }}>
                        {y.year}
                    </div>
                ))}
                {(() => {
                    const performedBy = emissionReport.find(
                        (y) => y.year === year
                    )?.reportingPerformedBy

                    if (performedBy)
                        return (
                            <div
                                style={{
                                    marginLeft: 32,
                                    marginTop: 4,
                                    marginBottom: 8,
                                    fontSize: 14,
                                }}>
                                Analysis and reporting performed by {performedBy}
                            </div>
                        )
                })()}
            </div>

            <WidgetView value={emissionReport.find((y) => y.year === year)?.chart} />
        </div>
    )
}
