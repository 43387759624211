import React, { useContext } from "react"
import { Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import { EditableImage } from "../../../../packages/editing/EditableImage"
import { EditableText } from "../../../../packages/editing/EditableText"
import { GroupBy } from "../../../../reactor/Helpers"
import { AccountContext } from "../AccountPage"
import { colors, Title } from "../styles"
import { useAppearAnimation } from "../../../../packages/appear-animation/useAppear"
import { Section } from "../../../../packages/editing/Section"

Section(RelatedFrameworks)
function RelatedFrameworks(section: {}) {
    const solution = useContext(AccountContext)
    const { ref, style } = useAppearAnimation()

    if (!solution) return <></>

    const frameworks = GroupBy(
        (solution.sdgs || []).concat(solution.nonSdgs || []),
        (g) => g.frameworkName
    )

    return (
        <div ref={ref} className="container" style={{ ...style, marginTop: 64 }}>
            <Title title="Related Frameworks" />
            {frameworks.length === 0 ? "This account has no associated frameworks yet." : undefined}
            {frameworks?.map((f) => (
                <>
                    <div
                        style={{
                            color: colors.foreground,
                            fontWeight: "bold",
                            fontSize: 20,
                            marginTop: 32,
                        }}>
                        {f.key}
                    </div>
                    {f.values.map((e) => (
                        <Link to={`/solutions?sdg=${e.id}`}>
                            <Row style={{ marginBottom: 16, marginTop: 16 }}>
                                <EditableImage
                                    obj={e}
                                    prop="image"
                                    options={{ width: 128 }}
                                    style={{
                                        width: 128,
                                        height: 128,
                                        minWidth: 128,
                                        borderRadius: 16,
                                    }}
                                />
                                <Col style={{ paddingLeft: 32, paddingTop: 16 }}>
                                    <div
                                        style={{
                                            color: colors.foreground,
                                            fontWeight: "bold",
                                            fontSize: 20,
                                        }}>
                                        {e.name}
                                    </div>
                                    <EditableText
                                        obj={e}
                                        prop="shortDescription"
                                        isMarkdown={true}
                                        style={{
                                            color: colors.foreground,
                                            fontSize: 20,
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Link>
                    ))}
                </>
            ))}
        </div>
    )
}
