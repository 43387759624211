import React, { useContext, useState } from "react"
import { Card } from "react-bootstrap"
import { ModalContext } from "../../packages/modal/Modal"
import { prettyCamel } from "../../reactor/Helpers"
import { GetReflectionInfo } from "../../reactor/ReflectionInfo"
import { server } from "../../server"
import { Busy } from "./Busy"
import { RButton } from "./Buttons"
import { ColorStyles } from "../../packages/ui"

export function ErrorView({ error }: { error: any }) {
    const modal = useContext(ModalContext)

    let body = (
        <table>
            {Object.keys(error).map((key) => (
                <tr>
                    <td style={{ width: 100 }}>{prettyCamel(key)}</td>
                    <td>{JSON.stringify(error[key])}</td>
                </tr>
            ))}
        </table>
    )
    const [details, setDetails] = useState(false)

    if (error.type === "TSQError") {
        body = (
            <div>
                <div>{error.detail}</div>
                <div
                    style={{
                        fontFamily: "monospace",
                        backgroundColor: ColorStyles.error[400],
                        marginTop: "1rem",
                        marginBottom: "1rem",
                    }}>
                    <span>{error.code?.substr(0, error.start.character + 1)}</span>
                    <span style={{ backgroundColor: ColorStyles.error[400] }}>
                        {error.code?.substr(
                            error.start.character + 1,
                            error.end.character - error.start.character
                        )}
                    </span>
                    <span>{error.code?.substr(error.end.character)}</span>
                </div>
                {details ? body : <></>}
                <RButton onClick={() => setDetails(!details)}>
                    {details ? "Hide" : "Show"} details
                </RButton>
            </div>
        )
    }
    const infoCard = (
        <Card style={{ margin: "4rem" }}>
            <Card.Header>{error.type}</Card.Header>
            <Card.Body>{body}</Card.Body>
        </Card>
    )

    if (error.status === 401) {
        // Waiting to be redirected to login page
        const smallLogo = GetReflectionInfo().model.logo?.smallPath
        if (smallLogo)
            return (
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                    <img src={`${server()}${smallLogo}`} />
                </div>
            )
        else {
            return <Busy backgroundColor="white" />
        }
    }

    return (
        <div
            style={{
                margin: "4rem",
                backgroundColor: "white",
                border: "1px solid #ddd",
                borderRadius: 8,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}>
            {error.status === 403 ? (
                <>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            padding: 32,
                            paddingTop: 64,
                            alignItems: "center",
                        }}>
                        <i
                            className="fas fa-user-shield"
                            style={{
                                fontSize: 50,
                                paddingRight: 32,
                                color: ColorStyles.error[400],
                            }}
                        />
                        <div
                            style={{
                                flexDirection: "column",
                                alignContent: "center",
                                alignItems: "center",
                                justifyItems: "center",
                                justifyContent: "center",
                            }}>
                            <div
                                style={{
                                    fontSize: 24,
                                    fontWeight: 500,

                                    color: ColorStyles.error[800],
                                }}>
                                You need permission to do this.
                            </div>
                            <div
                                style={{
                                    fontSize: 20,
                                    fontWeight: 400,
                                    color: ColorStyles.gray[600],
                                }}>
                                Contact an administrator to request permission.
                            </div>
                        </div>
                    </div>
                    {modal ? (
                        <RButton variant="primary" onClick={() => modal.close(undefined)}>
                            Close
                        </RButton>
                    ) : undefined}
                    {infoCard}
                </>
            ) : (
                infoCard
            )}
        </div>
    )
}
