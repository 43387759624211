import React from "react"
import { Card } from "./Card"
import { RegisterWidget, WidgetView } from "./WidgetView"
import { ImageToCSS } from "../../reactor"
import { Link } from "react-router-dom"
import { useHover } from "../hooks/useHover"

RegisterWidget<Card>("Card", ({ value }) => {
    const { hover, hoverProps } = useHover()
    const card = (
        <div
            {...hoverProps}
            style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                borderRadius: 8,
                boxShadow:
                    value.link && hover
                        ? "0px 10px 20px 0px #1018280F, 0px 10px 30px 0px #1018281A"
                        : "0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A",

                transform: value.link && hover ? "translateY(-2px)" : undefined,
                border: "1px solid #EAECF0",
                filter: value.link && hover ? "brightness(1.05)" : undefined,
                margin: 16,
                transition: "box-shadow 0.2s ease-in-out 0s, transform 0.2s ease-in-out 0s",
                height: value.height,
            }}>
            {value.image && (
                <div
                    style={{
                        backgroundImage: ImageToCSS(value.image),
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        height: 180,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                    }}
                />
            )}
            <div style={{ padding: 16 }}>
                <WidgetView value={value.content} />
            </div>
        </div>
    )

    if (value.link) {
        return <Link to={value.link.valueOf()}>{card}</Link>
    } else return card
})
