import React from "react"
import { Spinner } from "react-bootstrap"

export function Busy(props: { backgroundColor: string }) {
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                backgroundColor: props.backgroundColor,
            }}>
            <Spinner style={{ margin: "0 auto" }} animation="border" />
        </div>
    )
}
