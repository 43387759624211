import React, { useEffect, useRef } from "react"
import { Clone } from "../../reactor/Clone"
import type { Chart } from "./Chart"
import { RegisterWidget } from "./WidgetView"

import {
    Chart as ChartClass,
    LinearScale,
    CategoryScale,
    BarController,
    LineController,
    LineElement,
    PointElement,
    BarElement,
    Tooltip,
    Legend,
    Title,
    SubTitle,
    DoughnutController,
    ArcElement,
    Filler,
} from "chart.js"
import { BoxPlotController, BoxAndWiskers } from "@sgratzl/chartjs-chart-boxplot"
import { ColorStyles } from "../ui"
import { DoughnutChartWiskerLabels } from "../charts/DoughnutChartWiskerLabels"

RegisterWidget<Chart>("Chart", ({ value }) => <ChartWidget chart={value} />)

// We load this from CDN in the HTML file, so it should be available in the global scope
if (ChartClass) {
    ChartClass.defaults.font.weight = "400"
    ChartClass.defaults.font.size = 14
    ChartClass.defaults.color = "#212529"
    ChartClass.defaults.aspectRatio = 16 / 9

    // register boxchart in chart.js and ensure the defaults are set
    ChartClass.register(
        BoxPlotController,
        BoxAndWiskers,
        LinearScale,
        CategoryScale,
        BarController,
        BarElement,
        PointElement,
        LineElement,
        Tooltip,
        Legend,
        Title,
        SubTitle,
        LineController,
        DoughnutController,
        ArcElement,
        Filler
    )
}

export function ChartWidget(props: { chart: Chart }) {
    const ref = useRef<HTMLCanvasElement>(null)
    const chartRef = useRef<any>(null)

    useEffect(() => {
        if (ref.current) {
            const ctx = ref.current.getContext("2d")
            const config = Clone(props.chart.config)

            if (props.chart.yAxisLabels) {
                if (!config.options) config.options = {}
                if (!config.options.scales) config.options.scales = {}
                if (!config.options.scales.y) config.options.scales.y = {}
                config.options.scales.y.ticks = {
                    callback(value: any) {
                        return new Intl.NumberFormat(undefined, props.chart.yAxisLabels).format(
                            typeof value === "string" ? parseFloat(value) : value
                        )
                    },
                }
            }

            if (props.chart.plugins?.includes("wisker-labels")) {
                config.plugins ??= []
                config.plugins.push(DoughnutChartWiskerLabels(ColorStyles.gray[600]))
            }

            if (ctx) {
                chartRef.current = new ChartClass(ctx, config)
                chartRef.current.options.aspectRatio = props.chart.aspectRatio ?? 16 / 9
            }
        }
        return () => chartRef.current?.destroy()
    }, [ref.current, JSON.stringify(props.chart.config)])

    return (
        <div
            style={{
                flex: 1,
                maxWidth: props.chart.maxWidth ?? 1024,
                marginLeft: props.chart.marginHorizontal ?? props.chart.margin ?? 16,
                marginRight: props.chart.marginHorizontal ?? props.chart.margin ?? 16,
                marginTop: props.chart.marginVertical ?? props.chart.margin ?? 16,
                marginBottom: props.chart.marginVertical ?? props.chart.margin ?? 16,
            }}>
            <canvas ref={ref} style={{ maxWidth: "100%" }} />
        </div>
    )
}
