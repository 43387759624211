import { css } from "@emotion/react"
import { EditableText } from "../../../../packages/editing/EditableText"
import { useMenuContext } from "../MenuContext"
import { colors, hideOnPrint, responsiveCss } from "../styles"
import { Section } from "../../../../packages/editing/Section"
import Logo from "../assets/Logo"

Section("Footer", Footer)
export function Footer(section: {}) {
    const { footer } = useMenuContext()
    if (!footer) return <></>

    return (
        <div
            css={css({
                backgroundColor: colors.darkGreen,
                width: "100vw",
                color: "white",
            })}>
            <div
                css={css(
                    {
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        padding: "32px 18px",
                        maxWidth: 1296,
                        margin: "0 auto",
                    },
                    responsiveCss("min", "sm", {
                        flexDirection: "row",
                        padding: 48,
                        justifyContent: "space-between",
                    })
                )}>
                <div
                    css={css(
                        {
                            display: "flex",

                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: 40,
                        },
                        responsiveCss("min", "md", { marginBottom: 0, marginRight: 40 })
                    )}>
                    <Logo
                        color="white"
                        css={css(
                            { display: "none" },
                            responsiveCss("min", "md", {
                                display: "block",
                                width: "auto",
                                height: 72,
                                marginTop: 6,
                                marginRight: 32,
                            })
                        )}
                    />
                    <div
                        css={css({
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                        })}>
                        <EditableText
                            style={{ fontSize: 32, color: "white" }}
                            obj={footer}
                            prop="logoText"
                        />
                        <EditableText
                            style={{
                                color: colors.mediumGreen,
                                fontSize: 18,
                                fontWeight: "bold",
                                marginBottom: 16,
                            }}
                            obj={footer}
                            prop="subText"
                        />

                        <EditableText
                            obj={footer}
                            prop="address"
                            style={{ color: "white" }}
                            isMarkdown
                            css={css({ p: { marginBottom: 0 } })}
                        />
                    </div>
                </div>
                <div
                    css={css(
                        { display: "flex", flexDirection: "column" },
                        responsiveCss("min", "md", {
                            flexDirection: "row",
                            marginTop: 78,
                        }),
                        hideOnPrint
                    )}>
                    <div
                        css={css(
                            {
                                color: colors.white,
                                lineHeight: 1.5,
                                textDecoration: "underline",
                                marginBottom: 40,
                                a: {
                                    color: colors.white,
                                },
                            },
                            responsiveCss("min", "md", { marginRight: 64, marginBottom: 12 })
                        )}>
                        {footer.links.map((l) => (
                            <EditableText
                                key={l.text}
                                obj={l}
                                prop="text"
                                link={l.link.toString()}
                                display={(s) => (
                                    <a
                                        href={l.link}
                                        css={css({
                                            color: colors.mediumGreen,
                                        })}>
                                        {s}
                                    </a>
                                )}
                            />
                        ))}
                    </div>
                    <div
                        css={css({
                            a: {
                                color: colors.mediumGreen,
                                textDecoration: "underline",
                            },
                        })}>
                        <EditableText
                            key="socialLinks"
                            obj={footer}
                            isMarkdown={true}
                            prop="socialLinks"
                            defaultText="Follow us on Twitter"
                            css={css(
                                {
                                    lineHeight: 1.5,
                                    marginBottom: 0,
                                },
                                responsiveCss("min", "md", { marginBottom: 12 })
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
