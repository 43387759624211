import React from "react"
import type { WidgetMap } from "./Widget"
import { RegisterWidget, WidgetView } from "./WidgetView"
import { ColorStyles } from "../ui"

RegisterWidget<WidgetMap>(undefined, ({ value }) => {
    if (value === null) return
    if (typeof value !== "object") return

    // The lack of a `type` field is indicative of a table row - its basically a vanilla object
    if (value.type) return

    return (
        <div
            style={{
                fontSize: 14,
            }}>
            {Object.keys(value).map((key, i) => {
                const v = value[key]
                const widget =
                    typeof v === "string" ? <div>{v}</div> : <WidgetView value={value[key]} />

                return (
                    <div key={key} style={{ marginTop: i > 0 ? 24 : 0, fontWeight: 500 }}>
                        <div style={{ color: ColorStyles.gray[600], marginBottom: 8 }}>{key}</div>
                        <div style={{ color: ColorStyles.gray[700] }}>{widget}</div>
                    </div>
                )
            })}
        </div>
    )
})
