import React from "react"
import ReactDOM from "react-dom/client"

/** Initializes a web app, making sure it works well in both production and HMR mode. */
export function InitWebApp(
    RootComponent: React.FunctionComponent,
    /** ID of the DOM root element */
    rootId = "root"
) {
    const rootEl = document.getElementById(rootId)

    if (rootEl) {
        ReactDOM.hydrateRoot(rootEl, <RootComponent />)
    }
}
