import React from "react"
import { useMemo } from "react"
import { useDirtyContext } from "../../packages/editing/DirtyContext"
import {
    AreTypesAssignable,
    MatchesType,
    Property,
    Type,
    UndefinedType,
} from "../../reactor/Types/Type"
import { ColorStyles } from "../../packages/ui"
import { ClientSideLocalize } from "../../packages/localization/client-side/Dictionary"
import { IsLocalized } from "../../packages/localization/Localized"

export function useDiagnostic(type: Type, value: any, version?: any) {
    return useMemo(() => {
        const match = MatchesType(value, type, false)
        if (match === true) return null
        else return match
    }, [type, value, version])
}

export function DiagnosticView({
    value,
    property,
    noSubtree,
    overrideText,
}: {
    value: any
    property: Property
    // Used to force invalidation
    version?: {}
    noSubtree?: boolean
    overrideText?: string
}) {
    const { type, optional } = property
    const { version } = useDirtyContext()
    const diagnostic = useDiagnostic(type, value, version)

    let text = diagnostic?.error
    const requiredAndUndefined =
        value === undefined &&
        !optional &&
        !AreTypesAssignable(type, UndefinedType) &&
        !property.tags?.serial
    const localizedAndNoLocales =
        value &&
        typeof value === "object" &&
        IsLocalized(property.type) &&
        (Object.keys(value).length === 0 || Object.values(value).every((v) => !v))
    if (requiredAndUndefined || localizedAndNoLocales) {
        text = ClientSideLocalize({
            en: "Required",
            no: "Påkrevd",
            de: "Erforderlich",
            sv: "Obligatorisk",
            da: "Påkrævet",
        })
    }
    if (overrideText) {
        text = overrideText
    }

    if (
        !text ||
        (value === undefined && optional) ||
        (!noSubtree && diagnostic?.subErrors?.length)
    ) {
        return <></> // Will be displayed by a sub-component
    }

    return (
        <div
            style={{
                color: ColorStyles.error[500],
                fontSize: 12,
                paddingLeft: 8,
                paddingRight: 8,
                backgroundColor: ColorStyles.error[100],
                borderRadius: 8,
            }}>
            {text}
        </div>
    )
}
