import { css } from "@emotion/react"
import { EditableText } from "../../../../packages/editing/EditableText"
import { Section } from "../../../../packages/editing/Section"
import { Markdown } from "../../../../reactor/Types/Primitives/Markdown"
import { responsiveCss } from "../styles"
type SpacingOptions = "normal" | "reduced" | "none"
Section(HeadingAndTextSection)
/**
 * @zoom 3
 */
function HeadingAndTextSection(section: {
    heading?: string

    /** Whether this is an extra large heading, e.g. key figure  */
    largeHeading: boolean

    /**
     * @default "Content goes here"
     */
    body?: Markdown

    /** Whether the body text should be larger than normal */
    largeBody: boolean

    /**
     * How text is aligned within the section. For the smallest
     * screens like mobile, left alignment will be forced.
     * @default "Center"
     */
    alignment: "Left" | "Center" | "Right"

    /**
     * Max width of the section
     */
    maxWidth?: number

    /**
     * Override default spacing on top and/or bottom of section.
     * @expanded
     */
    spacing?: {
        /**
         * @default "normal"
         */

        top: SpacingOptions

        /**
         * @default "normal"
         */
        bottom: SpacingOptions
    }
}) {
    const spacing = { normal: 64, reduced: 32, none: 0 }

    return (
        <section
            css={css(
                {
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                },
                responsiveCss("min", "sm", {
                    alignItems:
                        section.alignment === "Center"
                            ? "center"
                            : section.alignment === "Left"
                            ? "flex-start"
                            : "flex-end",
                })
            )}>
            <div
                style={{
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    // Default maxWidth is 1200 + left and right side padding
                    maxWidth: section.maxWidth || 1296,
                }}
                css={css(
                    {
                        paddingTop: spacing[section.spacing?.top || "normal"] * 0.75,
                        paddingBottom: spacing[section.spacing?.bottom || "normal"] * 0.75,
                        paddingLeft: 24,
                        paddingRight: 24,
                        alignItems: "flex-start",
                    },
                    responsiveCss("min", "sm", {
                        paddingLeft: 48,
                        paddingRight: 48,
                        paddingTop: spacing[section.spacing?.top || "normal"],
                        paddingBottom: spacing[section.spacing?.bottom || "normal"],
                        alignItems:
                            section.alignment === "Center"
                                ? "center"
                                : section.alignment === "Left"
                                ? "flex-start"
                                : "flex-end",
                    })
                )}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                    }}
                    css={css(
                        { alignItems: "flex-start" },
                        responsiveCss("min", "sm", {
                            alignItems:
                                section.alignment === "Center"
                                    ? "center"
                                    : section.alignment === "Left"
                                    ? "flex-start"
                                    : "flex-end",
                        })
                    )}>
                    {!!section.heading && (
                        <h1
                            css={css(
                                { textAlign: "left" },
                                responsiveCss("min", "sm", {
                                    textAlign:
                                        section.alignment === "Center"
                                            ? "center"
                                            : section.alignment === "Left"
                                            ? "left"
                                            : "right",
                                }),
                                !!section.largeHeading && {
                                    fontSize: 80,
                                }
                            )}>
                            <EditableText obj={section} prop="heading" />
                        </h1>
                    )}
                    {!!section.body && (
                        <div
                            css={css(
                                {
                                    marginTop: !!section.heading ? 8 : 0,
                                    textAlign: "left",
                                },
                                responsiveCss("min", "sm", {
                                    marginTop: !!section.heading ? 12 : 0,
                                    textAlign:
                                        section.alignment === "Center"
                                            ? "center"
                                            : section.alignment === "Left"
                                            ? "left"
                                            : "right",
                                }),
                                !!section.largeBody && { fontSize: 20, lineHeight: "normal" },
                                !!section.largeBody &&
                                    responsiveCss("min", "sm", {
                                        fontSize: 24,
                                        lineHeight: "normal",
                                    })
                            )}>
                            <EditableText obj={section} prop="body" isMarkdown={true} />
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}
