import React, { useContext } from "react"
import { useState } from "react"
import type { Widget, WidgetMap } from "../../packages/widgets/Widget"
import { ExtractPropFromWidget } from "../../packages/widgets/Helpers"
import { IsTable } from "../../packages/widgets/WidgetTypeHelpers"
import { DocumentContext } from "./DocumentContext"
import { WidgetContextProvider } from "../../packages/widgets/WidgetContextProvider"
import { postStudioPage, postStudioPageAction } from "../client"
import { TableWidget } from "../../packages/widgets/TableWidget"
import { Strings, Translations } from "../../packages/localization/client-side/Dictionary"
import { Localized } from "../../packages/localization/Localized"

type DocumentPickerPropsBase = {
    /** The set of entities to select from.
     *
     *  Can be one of (in order of precedence in case of conflict)
     *  - Name of a collection
     *  - Name of an options function
     *  - Name of a union type (selects between the members of the union)
     *  - Name of a top-level folder that the user has access to (e.g. "Media")
     */
    collection: string
    obj?: object
    refKey?: string
    /** The document used as context for the picker. This is necessary in some
     * contexts, e.g. when requesting a list of options for a reference field.
     */
    document?: {}
    current: Widget
    itemName?: Localized<string>

    /**
     * A set of primary keys to exclude from the picker options.
     *
     * This is useful when the picker is used to replace a reference, or the
     * reference is not allowed to point to itself.
     *
     */
    excludePrimaryKeys?: string[]
}
type DocumentReplaceScope = "only-this" | "all-in-document"
type DocumentPickerProps = DocumentPickerPropsBase & {
    itemSelected: (row: WidgetMap, scope: DocumentReplaceScope) => void
    cancel: () => void
    /** The component to display while loading */
    busy?: () => JSX.Element
}

export function DocumentPicker(props: DocumentPickerProps) {
    const docContext = useContext(DocumentContext)
    const [scope, setScope] = useState<DocumentReplaceScope>("only-this")

    const itemName = props.itemName ?? { en: "Item", sv: "Objekt", no: "Objekt" }

    return (
        <WidgetContextProvider
            busy={props.busy}
            path={["picker", props.collection]}
            fetcher={async (req) =>
                await postStudioPage({
                    path: props.collection,
                    ignoreStudioPages: true,
                    document: props.document ?? docContext?.doc,
                    object: props.obj,
                    refKey: props.refKey,
                    excludePrimaryKeys: props.excludePrimaryKeys,
                    ...req,
                })
            }
            callAction={async (req) =>
                await postStudioPageAction({
                    path: props.collection,
                    ...req,
                })
            }>
            {(table) => {
                if (!IsTable(table)) return <></>

                const primaryText = ExtractPropFromWidget(props.current, "primary")
                table.title =
                    primaryText === undefined || primaryText === "undefined"
                        ? Strings["Please specify"]
                        : `${Strings.Replace} '${primaryText}'`
                table.subTitle =
                    primaryText === undefined || primaryText === "undefined"
                        ? Translations.SelectX(itemName)
                        : Translations.SelectXToRepalceY(itemName, primaryText)
                table.onRowClicked = (doc) => props.itemSelected(doc, scope)
                table.primaryAction = {
                    icon: "times",
                    text: Strings.Cancel,
                    onClicked: props.cancel,
                }
                table.hideAddButton = true
                table.hideTitle = false

                return (
                    <TableWidget
                        {...table}
                        titleButtons={
                            <select
                                className="reactor-document-picker-reference-mode"
                                value={scope}
                                onChange={(e) => setScope(e.target.value as any)}
                                style={{
                                    fontSize: 12,
                                    padding: 4,
                                    border: "1px solid #eee",
                                    borderRadius: 8,
                                    maxWidth: 200,
                                }}>
                                <option value="only-this">
                                    {Translations.ReplaceOnlyThisReference()}
                                </option>
                                <option value="all-in-document">
                                    {Translations.ReplaceAllReferencesInDocument(
                                        primaryText ?? "(?)"
                                    )}
                                </option>
                            </select>
                        }
                    />
                )
            }}
        </WidgetContextProvider>
    )
}
