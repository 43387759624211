import React, { useContext, useRef, useState } from "react"
import {
    FileToUrl,
    GetFileTypeMimeTypes,
    ImageOptions,
    ImageToCSS,
    ImageToUrl,
} from "../../reactor/Types/File"
import { DocumentContext, useDocumentContext } from "./DocumentContext"
import { PropRow } from "./PropRow"
import { ToolButton } from "./ToolButton"
import { useDropzone } from "react-dropzone"
import { DiagnosticView } from "./DiagnosticView"
import { Icon } from "./Icon"
import { DocumentPicker } from "./DocumentPicker"
import { Lottie } from "./Lottie"
import { useIsReadonlyField } from "./ObjectContext"
import { useHover } from "../../packages/hooks/useHover"
import { FileShareSetting, useFileInfo } from "../client"
import { useDirtyContext } from "../../packages/editing/DirtyContext"
import { Modal } from "../../packages/modal/Modal"
import { Camera } from "./Camera"
import { UploadFile } from "../../packages/files/UploadFile"
import { server } from "../../server"
import { ColorStyles } from "../../packages/ui"
import { useImageWithAuth } from "../../packages/files/useImageWithAuth"
import { Property } from "../../reactor/Types/Type"
import { prettyCamel } from "../../reactor/Helpers"
import { Uuid } from "../../reactor/Types/Primitives/Uuid"
import { Translations } from "../../packages/localization/client-side/Dictionary"
import { RButton } from "./Buttons"

type FileViewProps = {
    obj: any
    label?: string
    property: Property
    buttons: ToolButton[]
    icon: string
    isEmbedded?: boolean
}

export type UploadFileInfo = {
    folder: string
    acceptedMimeTypes: string[]
    share?: FileShareSetting | "FolderDefault"
    temporaryFile: boolean
    obj: any
    property: Property
    docContext: null | DocumentContext
    setDirty: () => void
    setState: (newState: UploadState) => void
}

type UploadState = "show" | "upload" | "capture"

export function FileView({ obj, property, buttons, icon, isEmbedded, label }: FileViewProps) {
    const [state, setState] = useState<UploadState>("show")
    const { setDirty } = useDirtyContext()
    const docContext = useDocumentContext()
    const { hoverProps } = useHover()
    const isReadonly = useIsReadonlyField(property)

    const acceptedMimeTypes = GetFileTypeMimeTypes(property.type)
    const privateFile = !!property.tags?.privateFile
    const temporaryFile = !!property.tags?.temporaryFile
    // Files are uploaded to the "Media" folder by default, but can be
    // overridden with a `@folder` tag on the property
    const folder = property.tags?.folder || "Media"
    const locked = property.tags?.locked

    const uploadFileInfo = useRef<UploadFileInfo>({
        folder,
        acceptedMimeTypes,
        share: privateFile ? undefined : "FolderDefault",
        temporaryFile,
        obj,
        property,
        docContext,
        setDirty,
        setState,
    })

    // The obj might change, but we need to reuse the same
    // uploadFileInfo.current object as it sits in a closure
    uploadFileInfo.current.obj = obj

    const onDrop = (acceptedFiles: File[]) => {
        const file = acceptedFiles[0]
        const data = new FormData()
        data.append("file", file, file.name)
        void uploadFile(file, uploadFileInfo.current)
    }
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true })
    const dropzoneRootProps = locked ? {} : getRootProps()

    const value = obj[property.name]
    const acceptsImage = acceptedMimeTypes.includes("image")
    const fileViewButtons = createButtons(state, obj, property, acceptsImage, setState, setDirty)
    const uploadFileFunc = createUploadFileFunc(uploadFileInfo.current)
    const noFileText = locked
        ? Translations.LockedField()
        : Translations.DragAndDropAFileHereToInsert()

    return (
        <PropRow
            isReadonly={isReadonly}
            label={label ?? prettyCamel(property.name)}
            badge={<ButtonRow property={property} value={value} />}
            description={property.description}
            descriptionAbove={true}
            isEmbedded={isEmbedded}
            buttons={[...buttons, ...fileViewButtons]}>
            <div style={{ display: "flex", flexDirection: "column" }}>
                {state === "upload" && <UploadView uploadFileInfo={uploadFileInfo.current} />}
                {state === "capture" && <Camera uploadFile={uploadFileFunc} />}
                {state === "show" ? (
                    <div
                        {...dropzoneRootProps}
                        {...hoverProps}
                        style={{
                            minHeight: 60,
                            maxHeight: 400,
                            backgroundColor: value ? undefined : ColorStyles.gray[50],
                            color: ColorStyles.gray[500],
                            padding: value ? 0 : "2rem",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: value ? "flex-start" : "center",
                        }}>
                        <input {...getInputProps()} />
                        {value === undefined && noFileText}
                        {value !== undefined && (
                            <FilePreviewWrapper
                                value={value}
                                icon={icon}
                                dragNDrop={isDragActive}
                            />
                        )}
                        {acceptsImage ? (
                            <RButton icon="ui-camera-01" onClick={() => setState("capture")} />
                        ) : undefined}
                        <RButton icon="ui-upload-01" onClick={() => setState("upload")} />
                    </div>
                ) : (
                    <RButton onClick={() => setState("show")}>Cancel</RButton>
                )}
            </div>
        </PropRow>
    )
}

function createUploadFileFunc(uploadFileInfo: UploadFileInfo) {
    return async (file: File) => await uploadFile(file, uploadFileInfo)
}

async function uploadFile(file: File, uploadFileInfo: UploadFileInfo) {
    const {
        folder,
        acceptedMimeTypes,
        share,
        temporaryFile,
        obj,
        property,
        docContext,
        setDirty,
        setState,
    } = uploadFileInfo
    const data = new FormData()
    data.append("file", file)

    // Determine the mime type of JSON files based on what the model accepts for the target
    // property
    let mimetype: string | undefined
    if (file.type === "application/json") {
        if (
            !acceptedMimeTypes.includes("application/json") &&
            acceptedMimeTypes.includes("application/lottie+json")
        ) {
            mimetype = "application/lottie+json"
        }
    }

    if (acceptsType(mimetype || file.type, acceptedMimeTypes)) {
        const res = await UploadFile(folder, file, mimetype, temporaryFile, share)
        if (res) {
            obj[property.name] = res
            setDirty()
            if (docContext?.refresh) await docContext?.refresh()
            setState("show")
        }
    }
}

function acceptsType(mimetype: string, acceptedMimeTypes: string[]) {
    if (acceptedMimeTypes.some((x) => x === "application/octet-stream")) return true
    const accepts = acceptedMimeTypes.some((x) => mimetype.startsWith(x))
    if (!accepts)
        alert(
            "Unsupported file type: " +
                mimetype +
                ". This property only accepts " +
                acceptedMimeTypes.reduce((a, b) => a + " or " + b)
        )
    return accepts
}

function ButtonRow({ property, value }: { property: Property; value: any }) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 4,
            }}>
            <DiagnosticView property={property} value={value} />
        </div>
    )
}

function createButtons(
    state: UploadState,
    obj: any,
    property: Property,
    acceptsImage: boolean,
    setState: (newState: UploadState) => void,
    setDirty: () => void
): ToolButton[] {
    // Files are uploaded to the "Media" folder by default, but can be
    // overridden with a `@folder` tag on the property
    const folder = property.tags?.folder || "Media"
    const locked = property.tags?.locked

    const buttons: ToolButton[] = [
        {
            text: "Upload",
            onClick: () => (state === "upload" ? setState("show") : setState("upload")),
        },
        {
            text: "Browse",
            onClick: async () => {
                const pk = await BrowseModal(obj, property.name, folder)
                if (pk) {
                    obj[property.name] = pk
                    setDirty()
                }
            },
        },
        {
            text: "Camera",
            onClick: () => (state === "capture" ? setState("show") : setState("capture")),
            disabled: !acceptsImage,
        },
    ]

    return locked ? [] : buttons
}

function UploadView({ uploadFileInfo }: { uploadFileInfo: UploadFileInfo }) {
    return (
        <input
            type="file"
            style={{ marginTop: "1rem", marginBottom: "1rem" }}
            onChange={(e) => {
                const file = e.target.files && e.target.files[0]

                if (file) {
                    void uploadFile(file, uploadFileInfo)
                } else {
                    e.target.value = ""
                }
            }}
        />
    )
}

function FilePreviewWrapper({
    value,
    icon,
    dragNDrop,
}: {
    value: any
    icon: string
    dragNDrop: boolean
}) {
    return (
        <div style={dragNDrop ? { opacity: 0.5 } : {}}>
            <FilePreview
                id={value}
                imageOptions={{ height: 400, width: 800 }}
                objectFit="contain"
                icon={icon}
            />
        </div>
    )
}

export function FilePreview({
    id,
    imageOptions,
    objectFit,
    icon,
}: {
    id: Uuid
    imageOptions: ImageOptions
    objectFit: "cover" | "contain"
    icon: string
}) {
    const contextFiles = useContext(DocumentContext)?.files
    const contextFile = contextFiles !== undefined ? contextFiles[id.toString()] : undefined
    const contextMimeType = contextFile !== undefined ? contextFile.mimetype : undefined

    // Fetch file info if we didn't find file mimetype in document context
    const { data: fetchedFile } = useFileInfo(contextMimeType ? null : (id as any))
    const fetchedMimeType = fetchedFile !== undefined ? fetchedFile.mimetype : undefined

    const fileInfo = contextFile !== undefined ? contextFile : fetchedFile
    const mimetype = contextMimeType !== undefined ? contextMimeType : fetchedMimeType
    const protectedFile = fileInfo !== undefined ? fileInfo.share !== "AnyoneWithLink" : false

    // If the image is private, fetch it with auth headers
    const image = useImageWithAuth(
        protectedFile && mimetype?.startsWith("image/")
            ? ImageToUrl(id as any, imageOptions)
            : undefined
    )

    if (!mimetype) return <div>Unknown mime type for {id}</div>

    if (!id || (id as any) === "undefined")
        return (
            // Missing images from tableView come in as `$FILE:undefined`
            <div
                style={{
                    maxWidth: 80,
                    height: "100%",
                    borderRadius: 48,
                    backgroundColor: "#ddd",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                <i className={`fas fa-${icon}`} style={{ color: "#444" }}></i>
            </div>
        )
    //PDF preview
    if (mimetype.startsWith("application/pdf")) {
        return (
            <iframe
                style={{ width: "100%", height: "75vh" }}
                src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=${server()}/api/files/${id}`}></iframe>
        )
    }

    if (mimetype.startsWith("application/lottie+json"))
        return <Lottie file={id as any} style={{ width: 400, height: 400 }} />

    if (mimetype.startsWith("audio/")) return <audio controls={true} src={FileToUrl(id as any)} />

    if (mimetype.startsWith("video/"))
        return (
            <video
                controls={true}
                style={{ width: "100%", maxWidth: 400 }}
                src={FileToUrl(id as any)}
            />
        )

    if (mimetype.startsWith("image/") && !protectedFile)
        return (
            <div
                style={{
                    width: 350,
                    maxWidth: 400,
                    aspectRatio: "16 / 9",
                    backgroundImage: ImageToCSS(id as any, imageOptions),
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                }}
            />
        )

    if (mimetype.startsWith("image/") && protectedFile)
        return (
            <img
                src={image}
                style={{
                    width: "100%",
                    maxWidth: 400,
                    aspectRatio: "16 / 9",
                    objectFit: "contain",
                    objectPosition: "left",
                }}
            />
        )

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Icon
                icon="file"
                style={{
                    width: 32,
                    height: 32,
                    margin: "1rem",
                }}
            />
        </div>
    )
}

function BrowseModal(obj: any, propertyName: string, folder: string) {
    return Modal((close) => (
        <DocumentPicker
            collection={folder}
            obj={obj}
            refKey="id"
            current={obj[propertyName]}
            itemName={{ en: "File", no: "Fil", sv: "Fil" }}
            itemSelected={function (row): void {
                close(row._primaryKey)
            }}
            cancel={() => close(undefined)}
            // TODO: Make this work
            /*filter={
        acceptedMimeTypes
            .map((x) => `mimetype.startsWith(${JSON.stringify(x)})`)
            .reduce((a, b) => a + " || " + b) as any as TypeScript<any, boolean>
    }*/
        />
    ))
}
