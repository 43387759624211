import type { Localized } from "./Localized"
import { OpaqueString } from "../../reactor/Types/Opaque"
import { Image } from "../../reactor/Types/File"

/**
 * @shared
 */
export type LocaleKey = OpaqueString<"LocaleKey">
export function LocaleKey(key: string): LocaleKey {
    return key as any
}
/** International English locale (default). */
LocaleKey.en = LocaleKey("en")
/** Norwegian (bokmål). */
LocaleKey.no = LocaleKey("no")
/** Swedish. */
LocaleKey.sv = LocaleKey("sv")

/**
 * Represents a locale, which is a language and associated primary country.
 *
 * @icon ui-flag-02
 * @shared
 */
export type Locale = {
    /** UTF character for the flag */
    flag: string

    /** The key of the locale, e.g. `en` or `en-US`
     *  @primary
     */
    readonly key: LocaleKey

    /** The display name of the locale, e.g. "Norwegian" */
    displayName?: Localized<string>

    /** The locale to fall back to if a value is not specified for this locale. */
    fallback?: Locale["key"]

    /** Image of the flag.
     *
     * This is useful in some scenarios:
     * - Some clients don't support UTF flags.
     * - To create flag buttons, e.g. in a circle cutout.
     */
    flagImage?: Image
}
