import React from "react"
import ReactMarkdown from "react-markdown"
import { ImageToUrl } from "../../../reactor/Types/File"
import { FieldSchema } from "./client"

export function ValueDisplay({
    f,
    obj,
    raw,
}: {
    f: FieldSchema
    obj: any
    raw?: boolean
}): JSX.Element {
    function display() {
        const value = obj

        if (typeof value === "string") {
            if (f.schema === "Image") {
                return <img src={ImageToUrl(value)} style={{ width: 720, maxWidth: "100%" }} />
            }
            if (f.schema === "Markdown") {
                return (
                    <ReactMarkdown
                        renderers={{
                            image: ({
                                alt,
                                src,
                                title,
                            }: {
                                alt?: string
                                src?: string
                                title?: string
                            }) => (
                                <img
                                    alt={alt}
                                    src={src}
                                    title={title}
                                    style={{ maxWidth: "100%", width: 720 }}
                                />
                            ),
                        }}>
                        {value}
                    </ReactMarkdown>
                )
            }
            return (
                <div style={{ backgroundColor: "#f0f0f0", padding: 8, borderRadius: 8 }}>
                    {value}
                </div>
            )
        } else if (typeof value === "number") return <>{value}</>
        else if (typeof value === "boolean") return <>{value ? "Yes" : "No"}</>
        else if (value instanceof Array) {
            const itemType =
                typeof f.schema === "object" && "array" in f.schema ? f.schema.array : "string"
            return (
                <div
                    style={{
                        border: "1px solid #eee",
                        borderRadius: 8,
                        padding: 8,
                        paddingRight: 32,
                    }}>
                    {value.map((v, i) => (
                        <div style={{ borderTop: i > 0 ? "1px solid #eee" : undefined }}>
                            <ValueDisplay
                                obj={v}
                                raw={true}
                                f={{
                                    label: i.toString(),
                                    schema: itemType,
                                    description: "",
                                    name: i.toString(),
                                    required: true,
                                }}
                            />
                        </div>
                    ))}
                </div>
            )
        } else if (typeof value === "object") {
            const fields =
                typeof f.schema === "object" && "fields" in f.schema ? f.schema.fields : []

            return (
                <div style={{ marginLeft: 32, marginTop: 32 }}>
                    {fields.map((k) => (
                        <div>
                            <div>
                                <ValueDisplay obj={value[k.name]} f={k} />
                            </div>
                        </div>
                    ))}
                </div>
            )
        } else return <>{JSON.stringify(value)}</>
    }
    if (obj === undefined || obj === "") return <></>

    if (raw) return display()

    return (
        <div style={{ marginTop: 16, marginBottom: 16 }}>
            <div style={{ fontWeight: "bold", color: "#666" }}>{f.label}</div>
            <div style={{ fontStyle: "italic", color: "#666" }}>{f.description}</div>
            <div
                style={{
                    marginTop: 16,
                    backgroundColor: "#fff",
                    marginBottom: 32,
                }}>
                {display()}
            </div>
        </div>
    )
}
