/**
 * For server-side rendering, this points to synchronous server functions that
 * correspond to certain client functions. This allows SSR to resolve data
 * synchronously to generate a more populated first response.
 *
 * On the client, this is null.
 */
declare const ssrEndpoints: { [name: string]: any } | null

declare const ssrTransaction: {
    current?: {
        [query: string]: {
            promise: Promise<any>
            result?: any
            error?: any
            needsAuthenticatedRefresh?: boolean
        }
    }
    /** A set of callbacks to be called when the current SSR render is done,
     * that can manipulate the resulting DOM by calling methods on the provided
     * object.
     *
     * The keys are the names of the macros, and are used to avoid performing
     * the same effect multiple times in the same render. When injecting a
     * macro, the injecting code should check for the same key already existing
     * and decide what to do.
     *
     * This is typically used to inject things into the DOM that can not be
     * captured during regular React rendering, such as tags going into the
     * `<head>` of the document.
     */
    macros?: Map<string, (root: HTMLElement) => void>
}
;(globalThis as any).ssrTransaction = {}

/** Injected into the HTML, only available on the client in SSR mode */
declare const ssrResults:
    | {
          [query: string]: {
              result?: any
              error?: any
              needsAuthenticatedRefresh?: boolean
          }
      }
    | undefined
