import React from "react"
import { DateString } from "../../reactor/Types/Primitives/DateTime"
import type { DatePicker } from "./DatePicker"
import { usePerformWidgetAction, useWidgetState } from "./WidgetContext"
import { RegisterWidget } from "./WidgetView"

RegisterWidget<DatePicker>("DatePicker", ({ value }) => {
    const [current, setCurrent] = useWidgetState(value.label, "current", value.defaultValue)
    const performAction = usePerformWidgetAction()
    const widgetKey = value.widgetKey
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                color: "rgb(46, 42, 39)",
                margin: 8,
                marginLeft: 16,
                marginRight: 16,
            }}>
            <div style={{ fontSize: 14, marginBottom: 6 }}>{value.label}</div>
            <input
                className="form-control"
                type="date"
                value={current ? DateString.toLocal(current, value.precision) : undefined}
                onChange={async (e) => {
                    try {
                        await setCurrent(DateString(e.target.value))
                        if (widgetKey && value.methods?.includes("onChange"))
                            await performAction(widgetKey, "onChange")
                    } catch (err: any) {
                        alert(JSON.stringify(err))
                    }
                }}
            />
        </div>
    )
})
