import React from "react"
import { ButtonVariant, ContextButton, RButton } from "./Buttons"

export type ToolButton = {
    text: string
    disabled?: boolean
    primary?: boolean
    variant?: ButtonVariant
    onClick: () => void
    expanded?: boolean
}

export function RenderButtons(buttons: ToolButton[]) {
    return (
        !!buttons.length && (
            <ContextButton
                buttons={
                    <>
                        {buttons.map((b) => (
                            <RButton key={b.text} disabled={b.disabled} onClick={b.onClick}>
                                {b.variant === "primary" ? <b>{b.text}</b> : b.text}
                            </RButton>
                        ))}
                    </>
                }
            />
        )
    )
}
