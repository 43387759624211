import React, { CSSProperties } from "react"

export function Grid(props: {
    style?: CSSProperties
    children?: React.ReactNode
    columns?: 2 | 3
}) {
    return (
        <div
            className={
                props.columns === 2 ? "cp-grid-responsive-columns-2" : "cp-grid-responsive-columns"
            }
            style={{
                display: "grid",
                gap: 32,
                alignSelf: "flex-start",
                ...props.style,
            }}>
            {props.children}
        </div>
    )
}
