import React, {
    ElementRef,
    ForwardedRef,
    forwardRef,
    useImperativeHandle,
    useRef,
    useState,
} from "react"
import { Image, ImageToCSS } from "../../../reactor/Types/File"
import { Uuid } from "../../../reactor/Types/Primitives/Uuid"
import { Button } from "./Button"
import { CloseX, colors, Title } from "./styles"
import { useAccount } from "./client"
import { useDelay } from "../../../reactor/Web/useDelay"
import { useNavigate } from "../../../packages/hooks/useNavigate"
import { MarkdownView } from "../../../packages/markdown-edit/MarkdownView"

type SolutionPopup = {
    open(): void
}

export function useSolutionPopup() {
    const ref = useRef<ElementRef<typeof SolutionPopup>>(null)
    return {
        ref,
        open() {
            if (ref.current) {
                ref.current.open()
            }
        },
    }
}

export const SolutionPopup = forwardRef(
    (
        { onClose, solutionId }: { onClose: () => void; solutionId: Uuid<"Account"> },
        ref: ForwardedRef<SolutionPopup>
    ) => {
        const [selected, setSelected] = useState(false)

        const { data: solution } = useAccount(selected ? solutionId : null)

        const [direction, setDirection] = useState<"hiding" | "appearing">("appearing")
        const selectedDelay = useDelay(selected, 200)
        const selectedLongDelay = useDelay(selected, 500)

        useImperativeHandle(ref, () => ({
            open() {
                setDirection("appearing")
                setSelected(true)
            },
        }))

        const navigate = useNavigate()

        if (!solution || !(selected || selectedLongDelay)) return <></>

        const link = `/accounts/${solution.slug || solution.id}`

        function close(e: { stopPropagation(): void }) {
            setDirection("hiding")
            setSelected(false)

            if (onClose) onClose()
            e.stopPropagation()
        }

        const desc = solution.shortDescription || solution.description

        return (
            <div
                onClick={close}
                style={{
                    cursor: "auto",
                    display: selected || selectedLongDelay ? "flex" : "none",
                    flexDirection: "row",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: selected ? "#0008" : undefined,
                    transition: "all 0.2 ease",
                    zIndex: 100,
                    justifyContent: "center",
                    perspective: "800px",
                }}>
                <div
                    onClick={(e) => e.stopPropagation()}
                    style={{
                        backgroundColor: "white",
                        boxShadow: "0 3px 20px 0 rgb(0 0 0 / 50%)",

                        transform:
                            selectedDelay && selected
                                ? "scale(1) rotateX(0deg)"
                                : direction === "appearing"
                                  ? "scale(0.4) rotateX(40deg)"
                                  : "scale(0.4) rotateX(-40deg)",
                        opacity: selectedDelay && selected ? 1 : 0,
                        transition: "all 0.4s ease",
                        margin: 16,
                        maxWidth: 1024,
                        borderRadius: 8,
                        alignSelf: "center",
                        display: "flex",
                    }}>
                    <div
                        onClick={close}
                        style={{
                            position: "absolute",
                            cursor: "pointer",
                            right: 12,
                            top: 8,
                            padding: 8,
                            fontSize: 32,
                            color: colors.darkGreen,
                        }}>
                        <CloseX />
                    </div>
                    <div
                        style={{
                            backgroundImage:
                                solution.image && ImageToCSS(solution.image, { width: 500 }),
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            borderTopLeftRadius: 7,
                            borderBottomLeftRadius: 7,
                            flex: 1,
                            width: 500,
                            maxWidth: 500,
                            minHeight: 400,
                        }}
                    />
                    <div style={{ flex: 1, padding: 32, fontSize: 20 }}>
                        <Title title={solution.name} />
                        <div
                            style={{
                                display: "-webkit-box",
                                height: 200,
                                overflow: "hidden",
                                WebkitLineClamp: 7,
                                WebkitBoxOrient: "vertical",
                            }}>
                            {desc && <MarkdownView value={desc} />}
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", marginTop: 32 }}>
                            {solution &&
                                solution.funds?.map((fund) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginRight: 64,
                                        }}>
                                        <div
                                            style={{
                                                color: colors.darkGreen,
                                                fontSize: 36,
                                                fontWeight: "bold",
                                                display: "flex",
                                                flexDirection: "row",
                                                whiteSpace: "nowrap",
                                            }}>
                                            {percentWithTwoDigits.format(fund.percentOfFund)} %
                                        </div>
                                        <span style={{ fontWeight: 200 }}>share of</span>
                                        {fund.name}
                                    </div>
                                ))}
                            <div style={{ flex: 1 }} />
                            <div style={{ justifyContent: "flex-end", marginRight: 32 }}>
                                <Button text="Learn more" onClick={() => link && navigate(link)} />
                            </div>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: 32,
                                flexWrap: "wrap",
                            }}>
                            {solution &&
                                solution.sdgs
                                    ?.filter((e) => !!e.image)
                                    .slice(0, 8)
                                    .map((element) => (
                                        <FrameworkElementView image={element.image!} />
                                    ))}
                            {solution && solution.sdgs && solution.sdgs.length > 8 ? (
                                <span>... {solution.sdgs.length - 8} more</span>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: 8,
                                flexWrap: "wrap",
                            }}>
                            {solution &&
                                solution.nonSdgs
                                    ?.filter((e) => !!e.image)
                                    .map((element) => (
                                        <FrameworkElementView image={element.image!} />
                                    ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
)

const percentWithTwoDigits = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
})

export function FrameworkElementView(element: { image: Image }) {
    return (
        <div
            style={{
                width: 80,
                height: 80,
                marginBottom: 8,
                borderRadius: 8,
                marginRight: 8,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundImage: ImageToCSS(element.image, { width: 150 }),
            }}
        />
    )
}
