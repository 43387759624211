import React from "react"
import { EditableArray } from "../../../../packages/editing/EditableArray"
import { EditableObject } from "../../../../packages/editing/EditableObject"
import { EditableText } from "../../../../packages/editing/EditableText"
import { useNews } from "../client"
import { headerStyle } from "../styles"
import { SolutionView } from "./Solutions"
import { Section } from "../../../../packages/editing/Section"
import type { NewsArticle } from "../../Articles"
import type { NewsArticle as ClientNewsArticle } from "../client"

type NewsSection = {
    /** The selection of articles to display, in the specified order.
     *
     *  If unspecified, all articles marked with "Is news" are displayed.
     */
    articles?: NewsArticle["id"][]

    title?: string
}

Section(NewsSection)
function NewsSection(section: NewsSection) {
    return NewsSectionView({ section })
}
export function NewsSectionView({
    section,
    filter,
}: {
    section: NewsSection
    filter?: (article: ClientNewsArticle) => boolean
}) {
    const { data: news } = useNews(section.articles)

    if (filter && !news?.filter(filter).length) return <></>

    return (
        <div className="container" style={{ marginTop: 64, overflow: "hidden" }}>
            <EditableText
                obj={section}
                prop="title"
                style={{
                    ...headerStyle,
                    marginBottom: 64,
                    width: "100%",
                }}
            />
            {news && (
                <div className="row">
                    <EditableArray
                        arr={filter ? news.filter(filter) : news}
                        direction="row"
                        itemClassName="col-md"
                        itemTypeName="NewsArticle">
                        {(f, i) => (
                            <EditableObject obj={f} typeName="NewsArticle" actions={{}}>
                                <SolutionView
                                    key={f.id.toString()}
                                    index={i}
                                    width={350}
                                    link={"/articles/" + (f.slug || f.id)}
                                    maxBodyLength={undefined}
                                    transform="translateX(100px)"
                                    obj={{
                                        get title() {
                                            return f.title
                                        },
                                        set title(t) {
                                            f.title = t
                                        },
                                        get image() {
                                            return f.thumbnail || f.image
                                        },
                                        set image(img) {
                                            if (f.thumbnail) f.thumbnail = img
                                            else if (img) f.image = img
                                            else f.thumbnail = undefined
                                        },
                                        get body() {
                                            return f.ingress
                                        },
                                        set body(b) {
                                            f.ingress = b
                                        },
                                    }}
                                />
                            </EditableObject>
                        )}
                    </EditableArray>
                </div>
            )}
        </div>
    )
}
