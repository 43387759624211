import React, { CSSProperties, useContext, useEffect, useRef, useState } from "react"
import { DirtyContext } from "../editing/DirtyContext"
import { EditableContext } from "../editing/EditableContext"
import { Cell } from "./Cell"
import { usePerformWidgetAction } from "./WidgetContext"
import { RegisterWidget } from "./WidgetView"
import { ColorStyles } from "../ui"

RegisterWidget<Cell>("Cell", ({ value }) => <CellWidget cell={value} />)

export function CellWidget({ cell }: { cell: Cell }) {
    let [value, setValue] = useState(cell.value)

    const { editing } = useContext(EditableContext) ?? { editing: false }
    const { isDirty } = useContext(DirtyContext) ?? { isDirty: false }
    const editable = (cell.editable === "while-editing" ? editing : cell.editable) ?? true
    if (!editable) value = cell.value
    const performAction = usePerformWidgetAction()

    const style: CSSProperties = {
        border: !editable ? "none" : "1px solid " + ColorStyles.gray[200],
        borderRadius: 8,
        color: ColorStyles.gray[600],
        width: "100%",
        padding: 4,
        paddingLeft: 8,
        paddingRight: 8,
        flex: cell.flex ?? undefined,
        textAlign:
            cell.textAlign === "right" ? "end" : cell.textAlign === "center" ? "center" : undefined,
    }

    if (!editable) return <div style={style}>{value}</div>
    return (
        <InputOrTextarea
            isTextarea={cell.multiLine}
            placeholder={cell.placeholder}
            style={style}
            type="text"
            value={value === undefined ? undefined : "" + value}
            onClick={(e) => {
                e.stopPropagation()
            }}
            onChange={(e) => {
                setValue(e.target.value)
            }}
            onFocus={(e) => {
                if (isDirty) {
                    alert("You have unsaved changes, please save them before editing this cell.")
                    e.target.blur()
                }
            }}
            onBlur={async (e) => {
                if (value !== cell.value && cell.widgetKey) {
                    try {
                        const res = await performAction(cell.widgetKey, "save", [value])
                        setValue(res)
                    } catch (error: any) {
                        if ("detail" in error) {
                            alert(error.detail)
                        } else {
                            alert(JSON.stringify(error))
                        }
                    }
                }
            }}
        />
    )
}

type InputOrTextareaProps = React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> & {
    isTextarea?: boolean
}

const InputOrTextarea = ({ isTextarea, ...props }: InputOrTextareaProps) => {
    return isTextarea ? (
        <AutoExpandingTextarea {...props} />
    ) : (
        <input {...(props as any)} onFocus={(e) => e.target.select()} />
    )
}

const AutoExpandingTextarea = (props: InputOrTextareaProps) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
        const textarea = textareaRef.current
        if (textarea) {
            textarea.style.height = "auto" // Reset height to recalculate
            textarea.style.height = `${textarea.scrollHeight}px` // Set to scroll height
        }
    }, [props.value]) // Adjust height whenever the value changes

    return <textarea {...(props as any)} ref={textareaRef} />
}
