import React from "react"
import { useHover } from "../../../packages/hooks/useHover"
import { Arrow, colors } from "./styles"

export function Button(props: {
    text: string
    onClick: () => void
    style?: React.CSSProperties
    disabled?: boolean
    hideArrow?: boolean
    colors?: { regular: string; hover: string }
}) {
    const { hover, hoverProps } = useHover()

    const c = props.colors ? props.colors : { regular: colors.darkGreen, hover: colors.mediumGreen }

    return (
        <div
            {...hoverProps}
            style={{
                cursor: props.disabled ? undefined : "pointer",
                transform:
                    hover && !props.disabled
                        ? `scale(1.05) ${props.hideArrow ? "" : "translateX(10px)"}`
                        : undefined,
                transition: `all 0.35s ease`,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderRadius: 16,
            }}
            onClick={props.disabled ? undefined : props.onClick}>
            <div
                style={{
                    display: "inline",
                    borderRadius: 8,
                    color:
                        hover && !props.disabled
                            ? c.hover
                            : props.disabled
                            ? c.regular + "77"
                            : c.regular,
                    fontWeight: "bold",
                    fontSize: 22,

                    ...props.style,
                }}>
                {props.text}
            </div>
            {!props.hideArrow && (
                <div style={{ margin: 16, justifySelf: "center" }}>
                    <Arrow />
                </div>
            )}
        </div>
    )
}

export function AButton(props: {
    text: string
    onClick: () => void
    style?: React.CSSProperties
    disabled?: boolean
    hideArrow?: boolean
    colors?: { regular: string; hover: string }
}) {
    const { hover, hoverProps } = useHover()

    const c = props.colors ? props.colors : { regular: colors.darkGreen, hover: colors.mediumGreen }

    return (
        <div
            {...hoverProps}
            style={{
                cursor: props.disabled ? undefined : "pointer",
                transform:
                    hover && !props.disabled
                        ? `scale(1.05) ${props.hideArrow ? "" : "translateX(10px)"}`
                        : undefined,
                transition: `all 0.35s ease`,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderRadius: 16,
            }}
            onClick={props.disabled ? undefined : props.onClick}>
            <div
                style={{
                    display: "inline",
                    borderRadius: 8,
                    color:
                        hover && !props.disabled
                            ? c.hover
                            : props.disabled
                            ? c.regular + "33"
                            : c.regular,
                    fontWeight: "bold",
                    fontSize: 22,
                    margin: 16,

                    ...props.style,
                }}>
                {props.text}
            </div>
            {!props.hideArrow && (
                <div style={{ margin: 16, justifySelf: "center" }}>
                    <Arrow />
                </div>
            )}
        </div>
    )
}
