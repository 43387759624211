import type { Item } from "./Item"
import { RegisterWidget } from "./WidgetView"
import { UntitledUI } from "../untitled-ui"
import { ImageToCSS, ImageToUrl } from "../../reactor/Types/File"
import ReactMarkdown from "react-markdown"
import { ChipWidget } from "./ChipWidget"
import { Icon } from "../../studio/Views/Icon"
import { ColorStyles } from "../ui"
import { useImageWithAuth } from "../files/useImageWithAuth"
import { Color } from "../../reactor/Types/Color"

RegisterWidget<Item>("Item", ({ value, tableCell, hideDetails }) => {
    const size = value.largeImage ? 80 : 40
    const imageUrl = value.rawImageUrl ?? ImageToUrl(value.image as any, { width: size })
    const imageBase64 = useImageWithAuth(imageUrl)

    const colorSeed = value.primary

    let backgroundColor: string
    let color: string

    if (value.color) {
        if (UntitledUI.isColorStyle(value.color)) {
            backgroundColor = ColorStyles[value.color][100]
            color = ColorStyles[value.color][800]
        } else {
            backgroundColor = value.color
            color = Color.isDark(backgroundColor) ? "white" : "black"
        }
    } else {
        const randomColor = UntitledUI.randomSecondaryColor(colorSeed)
        backgroundColor = randomColor[100]
        color = randomColor[800]
    }

    const icon =
        imageBase64 || value.rawImageUrl || value.image ? undefined : value.icon ?? "ui-cube-01"

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flex: value.flex ?? undefined,
                fontSize: 14,
                marginRight: 8,
            }}>
            {!value.hideAvatar && (
                <div
                    style={{
                        width: size,
                        minWidth: size,
                        height: size,
                        borderRadius: value.imageStyle === "square-contain" ? 0 : 20,
                        backgroundColor: value.image ? "transparent" : backgroundColor,
                        marginRight: 12,
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        color,
                        backgroundPosition: "center",
                        backgroundSize: value.imageStyle === "square-contain" ? "contain" : "cover",
                        backgroundRepeat: "no-repeat",
                        WebkitPrintColorAdjust: "exact",
                        backgroundImage: imageBase64
                            ? `url(${imageBase64})`
                            : value.rawImageUrl
                              ? `url(${value.rawImageUrl})`
                              : value.image
                                ? ImageToCSS(value.image as any, { width: size })
                                : undefined,
                    }}>
                    {value.emoji && <div style={{ fontSize: 32 }}>{value.emoji}</div>}
                    {!value.emoji &&
                        icon &&
                        (value.initials ? (
                            <div style={{ marginTop: -2 }}>{value.initials}</div>
                        ) : (
                            <Icon
                                icon={icon}
                                color={color}
                                size={18}
                                style={{
                                    marginTop:
                                        // The Untiled UI icons look better without the margin
                                        icon.startsWith("ui-") ? undefined : 4,
                                }}
                            />
                        ))}
                </div>
            )}
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                {value.info ? (
                    <div
                        style={{
                            color: ColorStyles.primary[700],
                            fontWeight: 600,
                            marginBottom: 8,
                        }}>
                        {value.info}
                    </div>
                ) : undefined}
                <div
                    style={{
                        color: ColorStyles.gray[700],
                        fontSize: value.info ? 16 : undefined,
                        marginBottom: value.info ? 8 : undefined,
                        fontWeight: tableCell?.column === 0 ? 500 : 400,
                    }}>
                    {value.primaryIsMarkdown ? (
                        <ReactMarkdown>{value.primary}</ReactMarkdown>
                    ) : (
                        value.primary
                    )}
                </div>
                <div style={{ color: ColorStyles.gray[500] }}>{value.secondary}</div>
            </div>
            {!hideDetails &&
                value.chips &&
                (value.chips instanceof Array ? (
                    value.chips.map((chip, index) => (
                        <div style={{ marginLeft: 32 }}>
                            <ChipWidget value={chip} />
                        </div>
                    ))
                ) : (
                    <div style={{ marginLeft: 32 }}>
                        <ChipWidget value={value.chips} />
                    </div>
                ))}
        </div>
    )
})
