import React, { useEffect, useState } from "react"
import { Email, IsNode, Uuid } from "../../../reactor"
import { server } from "../../../server"
import { usePreference, useQueryString } from "../../../reactor/Web"
import { ColorStyles } from "../../../packages/ui"

export function Login() {
    const [redirect] = useQueryString("redirect")
    const [renew] = useQueryString("renew")
    const [reason] = useQueryString("reason")
    const [email, setEmail] = usePreference<string>("login-email", "")
    const [emailValid, setEmailValid] = useState<boolean>(false)

    const [sent, setSent] = useState<boolean>(false)
    const [hide, setHide] = useState<boolean>(false)

    // For some reason computing emailValid directly as a derived state from
    // `email` didn't work (the button was stuck on disabled), but computing it
    // in a useEffect did. Some weird React bug?
    useEffect(() => {
        if (email)
            try {
                Email(email)
                setEmailValid(true)
            } catch (err: any) {
                setEmailValid(false)
            }
    }, [email])

    // Intro animation
    const [frame, setFrame] = useState<number>(0)
    const [startTime] = useState(Date.now())
    const time = Date.now() - startTime
    if (time < 1500 && !IsNode()) requestAnimationFrame(() => setFrame(frame + 1))

    return (
        <div
            style={{
                height: "100%",
                width: "100%",
                background: "linear-gradient(180deg, #132C35 0%, #071E26 100%)",
                position: "relative",
            }}>
            <div
                style={{
                    backgroundImage: `url('${server()}/static/climatepoint.png')`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    height: 64,
                    width: 180,
                    marginLeft: 32,
                    marginTop: 16,
                    position: "absolute",
                    opacity: time > 250 ? 1 : 0,
                    transform: `translateY(${time > 250 ? 0 : -32}px)`,
                    transition: "all  0.375s ease",
                }}
            />
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                }}>
                <div
                    style={{
                        color: "white",
                        opacity: sent ? 1 : 0,
                        transform: sent ? `translateY(0)` : `translatey(-100px)`,
                        transition: "all 0.375s ease",
                        textAlign: "center",
                    }}>
                    <div style={{ marginBottom: 32 }}>
                        Thanks! If that email is in our system, you should receive a login link
                        shortly.
                    </div>

                    <div style={{ color: ColorStyles.teal[500] }}>
                        Didn't receive anything? Check your spam folders, and verify that the email
                        was correct. <br />
                        Please contact ClimatePoint if the problem persists.
                    </div>

                    <button
                        style={{
                            background: emailValid ? ColorStyles.teal[500] : "#ccc6",
                            color: "white",
                            marginTop: 32,
                            border: "none",
                            padding: 8,
                            paddingLeft: 32,
                            paddingRight: 32,
                            borderRadius: 8,
                            opacity: time > 1000 ? 1 : 0,
                            transform: `translateY(${time > 1000 ? 0 : 32}px)`,
                            transition: "all 0.375s ease",
                            zIndex: 2,
                        }}
                        onClick={async () => {
                            setSent(false)
                            setHide(false)
                        }}>
                        Try another email
                    </button>
                </div>
            </div>
            {!hide && (
                <div
                    key="login"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                    }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            opacity: sent ? 0 : 1,
                            transform: sent ? `translateY(100px)` : undefined,
                            transition: "all 0.375s ease",
                        }}>
                        {reason && (
                            <div
                                key="reason"
                                style={{
                                    color: ColorStyles.error[600],
                                    backgroundColor: ColorStyles.error[100],
                                    padding: 16,
                                    borderRadius: 8,
                                    marginBottom: 32,
                                    maxWidth: 300,
                                    textAlign: "center",
                                }}>
                                {reason === "already-used"
                                    ? "The login link you used was already used. Please request a new one."
                                    : reason === "expired"
                                      ? "The login link you used was expired. Please request a new one."
                                      : reason === "invalid"
                                        ? "The login link you used was invalid."
                                        : reason}
                            </div>
                        )}
                        <div
                            style={{
                                marginBottom: 32,
                                opacity: time > 500 ? 1 : 0,
                                transform: `translateY(${time > 500 ? 0 : -32}px)`,
                                transition: "all 0.375s ease",
                            }}>
                            Please enter your ClimatePoint user email address:
                        </div>
                        <input
                            className="form-control"
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="your-email@company.com"
                            autoFocus={true}
                            style={{
                                padding: 8,
                                borderRadius: 8,
                                width: 300,
                                textAlign: "center",
                                fontSize: 18,
                                border: "none",
                                background: "#fff3",
                                color: "white",
                                marginBottom: 32,
                                opacity: time > 750 ? 1 : 0,
                                transform: `translateX(${time > 750 ? 0 : -32}px)`,
                                transition: "all 0.375s ease",
                            }}
                        />
                        <button
                            disabled={!emailValid}
                            style={{
                                background: emailValid ? ColorStyles.primary[500] : "#ccc6",
                                color: "white",

                                border: "none",
                                padding: 8,
                                paddingLeft: 32,
                                paddingRight: 32,
                                borderRadius: 8,
                                opacity: time > 1000 ? 1 : 0,
                                transform: `translateY(${time > 1000 ? 0 : 32}px)`,
                                transition: "all 0.375s ease",
                            }}
                            onClick={async () => {
                                if (email)
                                    try {
                                        await fetch(`${server()}/api/studio/auth/send-login-link`, {
                                            method: "POST",
                                            headers: { "Content-Type": "application/json" },
                                            body: JSON.stringify({
                                                email: Email(email),
                                                nonce: Uuid().valueOf(),
                                                redirect: redirect ? redirect : undefined, // empty string should be undefined
                                                renew: renew ? Uuid(renew) : undefined, // empty string should be undefined
                                            }),
                                        })
                                    } catch (e: any) {
                                        alert("detail" in e ? e.detail : JSON.stringify(e))
                                    }

                                setSent(true)
                                setTimeout(() => setHide(true), 500)
                            }}>
                            Send me a login link
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}
