import React, { CSSProperties } from "react"
import { Email, ImageToCSS } from "../../reactor"
import { UntitledUI } from "../../packages/untitled-ui"
import { RButton } from "./Buttons"
import { useHover } from "../../packages/hooks/useHover"
import { StudioUser } from "../client"
import { ColorStyles } from "../../packages/ui"
import { SidebarTheme } from "./SidebarTheme"

export function Avatar({
    user: me,
    size,
    fontSize,
}: {
    user: StudioUser
    size?: number
    fontSize?: number
}) {
    size ||= 32
    return (
        <div style={{ display: "inline-block" }}>
            <div
                style={{
                    fontSize: fontSize || 14,
                    flexDirection: "row",
                    display: "flex",
                    justifyItems: "center",
                    alignItems: "center",
                }}>
                <div
                    style={{
                        width: size,
                        height: size,
                        backgroundColor: Avatar.getColor(me)[100],
                        backgroundImage:
                            me.avatar?.image &&
                            ImageToCSS(me.avatar?.image, { width: size, height: size }),
                        backgroundSize: "cover",
                        borderRadius: size || "1rem",
                        borderColor: me.avatar?.image ? Avatar.getColor(me)[500] : undefined,
                        borderWidth: 1,
                        borderStyle: me.avatar?.image ? "solid" : "none",
                        color: me.avatar?.image ? "transparent" : Avatar.getColor(me)[800],
                        textAlign: "center",
                        alignContent: "center",
                        verticalAlign: "middle",
                        lineHeight: (1.5 / 24) * size + "rem",
                    }}>
                    {me.avatar?.initials ||
                        (me.givenName
                            ? me.givenName.charAt(0) +
                              (me.familyName ? me.familyName.charAt(0) : "")
                            : Email.abbreviate(me.email))}
                </div>
            </div>
        </div>
    )
}
Avatar.getColor = (me: StudioUser) => {
    if (me.avatar?.color) return ColorStyles[me.avatar.color]
    else
        return UntitledUI.randomSecondaryColor(
            me.givenName && me.familyName ? `${me.givenName} ${me.familyName}` : me.email.toString()
        )
}

export function StudioUserView({
    me,
    onClick,
    logout,
    collapsed,
    style,
}: {
    me: StudioUser
    collapsed: boolean
    onClick?: () => void
    logout?: () => void
    style?: CSSProperties
}) {
    const { hover, hoverProps } = useHover()

    return (
        <>
            <div
                {...hoverProps}
                onClick={onClick}
                style={{
                    backgroundColor: hover
                        ? SidebarTheme.light
                            ? ColorStyles.gray[400]
                            : ColorStyles.primary[800]
                        : undefined,

                    borderRadius: 8,
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                    margin: 16,
                }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        cursor: "pointer",
                    }}>
                    <Avatar user={me} size={48} />
                    {!collapsed && (
                        <div
                            style={{
                                fontSize: 14,
                                flex: 1,
                                padding: "1rem",
                            }}>
                            <b
                                style={{
                                    color: SidebarTheme.light ? ColorStyles.gray[900] : "white",
                                }}>
                                {me.familyName && me.givenName
                                    ? me.givenName + " " + me.familyName
                                    : me.email}
                            </b>
                            <br />
                            <div
                                style={{
                                    fontSize: 11,
                                    color: SidebarTheme.light
                                        ? ColorStyles.gray[600]
                                        : ColorStyles.primary[200],
                                }}>
                                {me.email}
                            </div>
                        </div>
                    )}
                    {!collapsed && logout && (
                        <RButton
                            tooltip="Log out"
                            color={
                                SidebarTheme.light
                                    ? ColorStyles.gray[500]
                                    : ColorStyles.primary[300]
                            }
                            style={{ borderWidth: 0 }}
                            iconSize={20}
                            onClick={logout}
                            icon="ui-log-out-01"
                        />
                    )}
                </div>
            </div>
        </>
    )
}
