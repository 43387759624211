import React, { useState, useRef, useEffect } from "react"
import { IsNode } from "../../reactor/AssertNode"
import { usePreference } from "../../reactor/Web"
import { ErrorView } from "../../studio/Views/ErrorView"
import {
    WidgetActionRequest,
    WidgetActionResponse,
    WidgetRequest,
    WidgetResponse,
} from "./WidgetRequest"
import { Widget } from "./Widget"
import { useExecuteClientEffects, WidgetContext } from "./WidgetContext"

/** Provides the default implementation of WidgetContext */
export function WidgetContextProvider(props: {
    path: string[]
    busy?: () => JSX.Element
    fetcher: (request: WidgetRequest) => Promise<WidgetResponse>
    fetcherDeps?: any[]
    /** Whether the children-function handles any error.
     *
     *  If not set to true, an error will cause the entire widget context
     *  provider to render an error view.
     *
     *  Handling the error would mean e.g. to display the error in a modal or
     *  sidebar, while keeping some meaningful content visible.
     */
    handlesError?: boolean
    callAction: (request: WidgetActionRequest) => Promise<WidgetActionResponse>
    children: (content: Widget, error: any) => React.ReactNode
}) {
    const path = props.path.join("/")

    const [prefrence, setPreference] = usePreference(path, "{}")
    const state = useRef(JSON.parse(prefrence) || {})
    state.current = JSON.parse(prefrence) || {}

    const [page, setPage] = useState<Widget>(null)
    const [error, setError] = useState<any>(null)
    const stateVersion = useRef(0)

    const { executeClientEffects, modal } = useExecuteClientEffects()

    const refreshResolvers = useRef<(() => void)[]>([])
    const refresh = (newState?: any, version = ++stateVersion.current) => {
        return new Promise<void>((resolve, reject) => {
            const currentState = newState ?? state.current
            refreshResolvers.current.push(resolve)
            setTimeout(async () => {
                try {
                    // Check if the state has changed again the last 100 ms, if so, cancel to avoid
                    // spamming the server with expensive requests. (poor man's debounce)
                    if (version !== stateVersion.current) return

                    const res = await props.fetcher({ state: currentState })

                    setError(undefined)

                    // Check that the response is still the most recent one we have requested Otherwise,
                    // writing the state will overwrite the most recent state causing annoying glitches
                    if (version === stateVersion.current) {
                        setPreference(JSON.stringify(res.state))
                        setPage(res.content)
                    }
                    refreshResolvers.current.forEach((r) => r())
                    refreshResolvers.current = []
                } catch (e) {
                    setError(e)
                    reject(e)
                }
            }, 100)
        })
    }

    const setState = async (newState: any) => {
        if (JSON.stringify(newState) !== JSON.stringify(state.current)) {
            state.current = newState
            setPreference(JSON.stringify(newState))
            await refresh(newState)
        }
    }

    useEffect(() => {
        refresh().catch((e) => setError(e))
    }, props.fetcherDeps ?? [])

    const oldPath = useRef<string | null>(null)
    if (oldPath.current !== path) {
        oldPath.current = path
        if (!IsNode()) void refresh()
    }

    if (error && !props.handlesError) {
        return <ErrorView error={error} />
    }

    if (!page && props.busy && !error) return props.busy()

    async function performAction(
        widgetKey: string,
        method = "action",
        args?: any[],
        onResponse?: () => void
    ) {
        const res = await props.callAction({
            state: state.current,
            widgetKey,
            method,
            args,
        })
        setPreference(JSON.stringify(res.state))
        setPage(res.content)

        if (onResponse) {
            onResponse()
            // Allow onResponse changes to propagate before executing client effects
            setTimeout(() => executeClientEffects(res.effects), 10)
        } else {
            executeClientEffects(res.effects)
        }

        return res.result
    }

    return (
        <WidgetContext.Provider
            value={{
                state: state.current,
                setState,
                refresh,
                performAction,
                root: page,
            }}>
            {modal}
            {props.children(page, error)}
        </WidgetContext.Provider>
    )
}
