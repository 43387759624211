import React from "react"

export function StickyHeader({ children }: { children: React.ReactNode }) {
    return (
        <div
            style={{
                position: "sticky",
                zIndex: 9,
                top: 0,
                backgroundColor: "#ffffffe8",
                backdropFilter: "blur(10px)",
                WebkitBackdropFilter: "blur(10px)",
            }}>
            {children}
        </div>
    )
}
