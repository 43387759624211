import React, { CSSProperties, useState } from "react"
import { Email } from "../../../reactor/Types/Primitives"
import { ToggleWidget } from "../../../packages/widgets/ToggleWidget"
import { Button } from "./Button"
import { postContactRequest } from "./client"

export function ContactMe() {
    const [email, setEmail] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [subscribe, setSubscribe] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    if (submitted) {
        return (
            <div style={{ fontSize: 20, padding: 32 }}>
                Thanks for your interest in ClimatePoint.
                <div>We will get back to you shortly.</div>
            </div>
        )
    }

    return (
        <div style={{ fontSize: 20, padding: 32 }}>
            <div style={{ fontWeight: "bold" }}>Thanks for your interest in ClimatePoint.</div>
            <div style={{ marginTop: 16 }}>
                Please leave your contact info below, and we will get back to you shortly.
            </div>
            <div style={{ marginTop: 32 }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div style={{ width: 210 }}>Email:</div>
                    <input
                        type="text"
                        style={inputStyle}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div style={{ width: 210 }}>Company Name:</div>
                    <input
                        type="text"
                        style={inputStyle}
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                    />
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 16,
                        marginBottom: 32,
                    }}>
                    <div style={{ width: 220 }}>Subscribe to newsletter:</div>
                    <ToggleWidget value={subscribe} onChanged={setSubscribe} />
                </div>
                <Button
                    text={"Submit"}
                    disabled={!email || !companyName}
                    onClick={async () => {
                        try {
                            await postContactRequest({
                                email: Email(email),
                                companyName,
                                subscribeToNewsletter: subscribe,
                            })
                            setSubmitted(true)
                        } catch (e: any) {
                            alert(e.detail ?? e.message)
                        }
                    }}
                />
            </div>
        </div>
    )
}

const inputStyle: CSSProperties = {
    margin: 8,
    padding: 8,
    borderRadius: 8,
    borderWidth: 1.5,
    borderStyle: "solid",
    borderColor: "#ccc",
    width: 300,
}
