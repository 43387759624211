import React from "react"
import type { Coins } from "./Coins"
import { ImageToCSS } from "../../reactor/Types/File"
import { RegisterWidget } from "./WidgetView"
import { UntitledUI } from "../untitled-ui"

RegisterWidget<Coins>("Coins", ({ value }) => {
    let extra = 0
    if (value.max && value.coins.length > value.max) {
        extra = value.coins.length - value.max
    }

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            {value.coins?.slice(0, value.max).map((c, i) => (
                <div style={{ width: 17 }} title={c.tooltip}>
                    <div
                        style={{
                            backgroundImage: c.rawImageUrl
                                ? `url(${c.rawImageUrl})`
                                : c.image && ImageToCSS(c.image, { width: 28, height: 28 }),
                            backgroundColor:
                                c.color ??
                                UntitledUI.randomSecondaryColor(JSON.stringify(c) + i)[100],
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            width: 26,
                            height: 26,
                            borderRadius: 13,
                            borderWidth: 2,
                            borderColor: "white",
                            borderStyle: "solid",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 12,
                        }}>
                        {c.image ? undefined : c.initials}
                    </div>
                </div>
            ))}
            {extra > 0 && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        width: 26,
                        height: 26,
                        borderRadius: 13,
                        borderWidth: 1.5,
                        borderColor: "white",
                        borderStyle: "solid",
                        fontSize: 12,
                        fontWeight: 500,
                        textAlign: "center",
                    }}>
                    +{extra}
                </div>
            )}
        </div>
    )
})
