import React, { useState } from "react"
import type { TextBox } from "./TextBox"
import { useWidgetState } from "./WidgetContext"
import { RegisterWidget } from "./WidgetView"

RegisterWidget<TextBox>("TextBox", ({ value }: { value: TextBox }) => {
    const [current, setCurrent] = useWidgetState(value.widgetKey, "current", value.defaultValue)
    const [local, setLocal] = useState(current)

    function onChange(e: React.ChangeEvent<any>) {
        if (value.immediate) setCurrent(e.target.value)
        else setLocal(e.target.value)
    }
    function onBlur() {
        if (!value.immediate) setCurrent(local)
    }

    const editor = value.multiLine ? (
        <textarea
            value={value.immediate ? current : local || ""}
            className="form-control"
            onChange={onChange}
            onBlur={onBlur}
            style={{
                minHeight: 120,
            }}
        />
    ) : (
        <input
            type="text"
            value={value.immediate ? current : local || ""}
            className="form-control"
            onChange={onChange}
            onBlur={onBlur}
            style={{
                width: "100%",
            }}
        />
    )

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                color: "rgb(46, 42, 39)",
                margin: 8,
                marginLeft: 16,
                marginRight: 16,
            }}>
            <div style={{ fontSize: 14, marginBottom: 6 }}>{value.label}</div>
            {editor}
        </div>
    )
})
