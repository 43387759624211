import React from "react"
import { Link } from "react-router-dom"
import { Breadcrumbs } from "./Breadcrumbs"
import { RegisterWidget } from "./WidgetView"
import { ColorStyles } from "../ui"

RegisterWidget<Breadcrumbs>("Breadcrumbs", ({ value }) => <BreadcrumbsWidget {...value} />)

export function BreadcrumbsWidget({ crumbs }: Breadcrumbs) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                color: ColorStyles.gray[600],
                marginTop: 16,
                marginLeft: 16,
            }}>
            {crumbs.map((c, i) => (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginRight: 12,
                    }}>
                    {c.icon && <i className={`fas fa-${c.icon}`} style={{ marginRight: 8 }} />}
                    {c.link ? (
                        <Link
                            style={{ color: ColorStyles.primary[600], textDecoration: "none" }}
                            to={c.link}>
                            {c.text}
                        </Link>
                    ) : (
                        <div>{c.text}</div>
                    )}
                    {i < crumbs.length - 1 && (
                        <i
                            className="fas fa-chevron-right"
                            style={{
                                marginLeft: 16,
                                marginTop: 2,
                                color: ColorStyles.gray[400],
                            }}
                        />
                    )}
                </div>
            ))}
        </div>
    )
}
