// Register all the widiget renderers
import "./LayoutWidget"
import "./ChipWidget"
import "./CoinsWidget"
import "./ItemWidget"
import "./TitleWidget"
import "./ProgressWidget"
import "./TableWidget"
import "./EmptyWidget"
import "./ButtonWidget"
import "./MarkedWidget"
import "./ChartWidget"
import "./PickerWidget"
import "./TextBoxWidget"
import "./CopyButtonWidget"
import "./TableRowWidget"
import "./GoogleMapsWidget"
import "./AsyncWidgetView"
import "./ElementWidget"
import "./MarkupWidget"
import "./DatePickerWidget"
import "./CardWidget"
import "./KeyFigureWidget"
import "./CellWidget"
import "./BreadcrumbsWidget"
import "./LinkWidget"
import "./TabsWidget"
import "./CalendarWidget"
import "./WorldMapWidget"
import "./CircleChartWidget"
import "./BigCalendarWidget"
